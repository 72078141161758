import { ValidationError, array, mixed, number, object, string } from 'yup'
import { objValues, truthy } from '@lasso/shared/utils'
import { AdGroupSplitConditionOperators, AdGroupSplitConditionValue, ChannelType } from '@lasso/api-activation/activation'

import {
  WhenConditionCallback,
  extendSchema, msgCurrency, msgPercentage, oneOfEnum,
} from '@lasso/shared/validation'

import { getIsEndemicChannel, getMediaBidMinMax } from '../../../shared'

import { getLeversTotalBudget, isCompletionRateAvailable, isViewabilityAvailable } from './utils'
import { CardLeversSchemaShape } from './types'
export const leverBaseShape = {
  id: number().default(0),
  localId: string().default(''),

  name: string().default(''),
  bid: number().nullable().default(null),
  targetBudget: number().nullable().default(null),

  creativeIds: array(number().default(0)).default([]),

  deviceType: array(oneOfEnum(AdGroupSplitConditionValue).default(AdGroupSplitConditionValue.APP)).default([]),
  supplyType: array(oneOfEnum(AdGroupSplitConditionValue).default(AdGroupSplitConditionValue.APP)).default([]),
  viewability: number().nullable().default(null),
  completionRate: number().nullable().default(null),
  inventoryList: array(object({
    id: number().default(0),
    operator: oneOfEnum([...objValues(AdGroupSplitConditionOperators), null]).default(null),
  }).required()).default([]),
  targetingBlock: mixed().nullable().default(null),
}

export const extendBidSchema = (schema: typeof leverBaseShape['bid'], isDirectSold: boolean, channelId: ChannelType) => {
  if (isDirectSold) {
    return schema
  }

  const bidLimit = getMediaBidMinMax(channelId)

  return schema.required().minMax(bidLimit.min, bidLimit.max, msgCurrency)
}
export const extendTargetBudgetSchema = (schema: typeof leverBaseShape['targetBudget']) => schema.required().minMax(1, 99, msgPercentage)
export const extendViewabilitySchema = (schema: typeof leverBaseShape['viewability'], channelId: ChannelType) => {
  return isViewabilityAvailable(channelId) ? schema.minMax(1, 99, msgPercentage) : schema
}
export const extendCompletionRateSchema = (schema: typeof leverBaseShape['completionRate'], channelId: ChannelType) => {
  return isCompletionRateAvailable(channelId) ? schema.minMax(1, 99, msgPercentage) : schema
}

type SchemaInput = {
  advancedOptimization: boolean
  optimizationSelected: boolean
  isTargetingRequired: boolean
  channelId: ChannelType
}

export const buildCardLeversSchema = ({ isCardEnabled, isDirectSold, whenChannelId, whenOptimization }: {
  isCardEnabled: boolean
  isDirectSold: boolean
  whenChannelId: WhenConditionCallback<ChannelType>
  whenOptimization: WhenConditionCallback<Omit<SchemaInput, 'isTargetingRequired' | 'channelId'>>
}): CardLeversSchemaShape => {
  const leversBaseSchema = array(object(leverBaseShape).required()).default([])

  const baseSchemaShape = {
    levers: leversBaseSchema,
    totalAllocatedValidation: mixed(),
  }
  const baseSchema = object(baseSchemaShape).required()

  if (!isCardEnabled) {
    return { levers: baseSchema }
  }

  const buildSchema = ({ advancedOptimization, optimizationSelected, isTargetingRequired, channelId }: SchemaInput) => {
    if (!advancedOptimization) {
      return baseSchema
    }

    return extendSchema(baseSchemaShape, {
      levers: () => array(
        extendSchema(leverBaseShape, {
          name: schema => schema.required(),
          bid: schema => optimizationSelected ? schema : extendBidSchema(schema, isDirectSold, channelId),
          targetBudget: schema => extendTargetBudgetSchema(schema),
          viewability: schema => extendViewabilitySchema(schema, channelId),
          completionRate: schema => extendCompletionRateSchema(schema, channelId),
          creativeIds: schema => schema.min(1),
        })
          .required()
          .test(
            'hasAnyTargetingSelection',
            ({ deviceType, supplyType, inventoryList, viewability, completionRate }, context) => {
              if (!isTargetingRequired) {
                return true
              }

              const hasAnyTargetingSelection = Boolean(
                deviceType.length > 0
                || supplyType.length > 0
                || (isViewabilityAvailable(channelId) ? viewability !== null : false)
                || (isCompletionRateAvailable(channelId) ? completionRate !== null : false)
                || inventoryList.length > 0,
              )

              return hasAnyTargetingSelection || context.createError({
                path: `${context.path}.targetingBlock`,
                message: 'Please select and apply at least one type of targeting',
              })
            },
          ),
      )
        .default([])
        .test(
          'isTotalAllocatedBudgetValid',
          (levers, context) => {
            const isTotalCorrect = levers.some(lever => !lever.targetBudget) || getLeversTotalBudget(levers) === 100

            return isTotalCorrect || context.createError({
              path: 'levers.totalAllocatedValidation',
              message: 'Total Allocated must be 100%',
            })
          },
        )
        .test(
          'isNameUnique',
          (levers, context) => {
            const errors = levers
              .map(({ name, localId }, index): ValidationError | null => {
                if (!name) {
                  return null
                }

                const isNameUnique = levers.every((lever) => {
                  const isSameLever = lever.localId === localId

                  return isSameLever || lever.name !== name
                })

                return isNameUnique
                  ? null
                  : context.createError({
                      path: `${context.path}[${index}].name`,
                      message: 'Name must be unique',
                    })
              })
              .filter(truthy)

            return errors.length === 0 ? true : new ValidationError(errors)
          },
        ),
    })
  }

  return {
    levers: whenChannelId(baseSchema, channelId => whenOptimization(
      baseSchema,
      input => buildSchema({
        ...input,
        isTargetingRequired: !isDirectSold && !getIsEndemicChannel(channelId),
        channelId,
      })),
    ).required(),
  }
}
