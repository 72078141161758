import { extendSchema } from '@lasso/shared/validation'
import { ChannelType } from '@lasso/api-activation/activation'

import {
  extendBidSchema, extendCompletionRateSchema, extendTargetBudgetSchema, extendViewabilitySchema,
  leverBaseShape,
} from '../buildCardLeversSchema'
import { AdGroupLever } from '../types'

export const buildLeverFormSchema = ({ levers, optimizationSelected, isDirectSold, channelId }: {
  levers: AdGroupLever[]
  optimizationSelected: boolean
  isDirectSold: boolean
  channelId: ChannelType
}) => {
  return extendSchema(leverBaseShape, {
    id: schema => schema,
    localId: schema => schema,
    name: schema => schema.required(),
    bid: schema => optimizationSelected ? schema : extendBidSchema(schema, isDirectSold, channelId),
    targetBudget: schema => extendTargetBudgetSchema(schema),
    viewability: schema => extendViewabilitySchema(schema, channelId),
    completionRate: schema => extendCompletionRateSchema(schema, channelId),
    creativeIds: schema => schema,
    deviceType: schema => schema,
    supplyType: schema => schema,
    inventoryList: schema => schema,
    targetingBlock: schema => schema,
  })
    .required()
    .test(
      'isNameUnique',
      ({ name, localId }, context) => {
        const isNameUnique = levers.every((lever) => {
          const isSameLever = lever.localId === localId

          return isSameLever || lever.name !== name
        })

        return isNameUnique || context.createError({
          path: 'name',
          message: 'Name must be unique',
        })
      },
    )
}
