import { useApiManual } from '@lasso/shared/hooks'
import { ChannelType, useActivationApi } from '@lasso/api-activation/activation'

import { AdGroupLever } from './types'
import { leverToApiSplit } from './utils'

export const useCardLeversSubmit = () => {
  const { requestThrows: updateLeversInternal } = useApiManual(useActivationApi().linkLevers)

  const updateLevers = async ({ levers, adGroupId, channelId }: {
    levers: AdGroupLever[]
    adGroupId: number
    channelId: ChannelType
  }) => {
    const activeSplits = levers.map((lever, index) => leverToApiSplit(lever, index + 1, channelId))

    await updateLeversInternal({
      adGroupID: adGroupId,
      adGroupSplitsArray: activeSplits,
    })
  }

  return { updateLevers }
}
